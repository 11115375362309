<template>
  <div class="grid flex-column">
    <div class="col" v-if="checkRole(['client'])">
      <Card class="h-auto">
        <template #content>
          <div class="grid grid-nogutter align-items-end">
            <div
              :class="columnNumber"
              class="col-12 pr-6"
              v-for="application in showProgressApplications"
              :key="application.id"
            >
              <div class="grid flex-column">
                <div class="col pb-0">
                  <span class="text-lg"
                    >{{ $t("applications.yourApplication") }} #{{
                      application.id
                    }}
                  </span>
                </div>
                <div class="col">
                  <ProgressBar
                    style="height: 1.2rem"
                    :value="application.completionPercentage"
                  />
                </div>
              </div>
            </div>
            <div class="col-12 text-right pb-0">
              <Button
                :label="$t('applications.newApplication')"
                @click="createApplication"
              />
            </div>
          </div>
        </template>
      </Card>
    </div>
    <div class="col">
      <ApplicationsTable :changeStatusButtons="true"></ApplicationsTable>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApplicationsTable from "./ApplicationsTable.vue";

export default {
  name: "Applications",
  components: {
    ApplicationsTable,
  },
  data() {
    return {
      progress: 10,
      showProgressApplications: [],
    };
  },
  mounted() {
    this.$action("application-page-view");
  },
  computed: {
    ...mapGetters(["checkPermission", "checkRole", "applications"]),
    columnNumber() {
      let colClass = "";
      switch (this.showProgressApplications.length) {
        case 1:
          colClass = "md:col-12";
          break;
        case 2:
          colClass = "md:col-6";
          break;
        case 3:
          colClass = "md:col-4";
          break;
        case 4:
          colClass = "md:col-6";
          break;
        default:
          colClass = "md:col-12";
          break;
      }
      return colClass;
    },
  },
  watch: {
    applications() {
      this.showProgressApplications = [];
      this.applications.forEach((el) => {
        if (el.completionPercentage < 100) {
          this.showProgressApplications.push(el);
        }
      });
    },
  },
  methods: {
    createApplication() {
      this.$router.push("createApplication");
      this.$store.commit("setApplicationPdf", {});
    },
  },
};
</script>

<style lang="scss" scoped>
.p-progressbar {
  border-radius: 100px;
}
</style>
